<template>
  <div>
    <v-row align="center" class="mx-1 my-3">
      <v-col cols="12" md="3">
        <v-text-field
          v-debounce:500ms="getItems"
          class="mr-1"
          v-model="fullName"
          :label="$t('bhth-bwasth-alism-althlathy')"
          hide-details=""
          prepend-inner-icon="search"
          dense=""
          outlined=""
          filled=""
        />
      </v-col>
    </v-row>

    <v-card outlined>
      <v-data-table
        class="mytable"
        :items-per-page="$global.state.filter.pageSize"
        :items="items"
        :loading="$global.state.loading"
        :headers="headers"
        hide-default-footer
        :loading-text="$t('loading')"
        :no-data-text="$t('no-data')"
        :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
      >
        <template v-slot:item.attends="{ item }">
          <div class="teal--text" v-if="item.attends.length > 0">
            {{
              new Date(item.attends[0].time)
                .toLocaleDateString("sv")
                .split("T")[0]
            }},
            <span>{{
              new Date(item.attends[0].time).toLocaleString().split(",")[1]
            }}</span>
          </div>
          <div v-else>
            <h4>
                {{ $t('lm-yhdhr') }}</h4>
          </div>
        </template>

        <template v-slot:item.type="{ item }">
          <div v-for="(type, i) in item.type" :key="i">
            <v-row class="ma-1">
              <v-chip
                small
                label
                dark
                v-if="type == 'بوابة الB3'"
                color="orange"
                >{{ type }}</v-chip
              >
              <v-chip
                small
                label
                dark
                v-if="type == 'بوابة الموظفين'"
                color="indigo"
                >{{ type }}</v-chip
              >
              <v-chip small label dark v-if="type == 'بوابة VIP'" color="red">{{
                type
              }}</v-chip>
              <v-chip
                small
                label
                dark
                v-if="type == 'بوابة الموظفين الداخلية'"
                color="indigo"
                >{{ type }}</v-chip
              >
              <v-chip
                small
                label
                dark
                v-if="type == 'بوابة  VIP الداخلية'"
                color="red"
                >{{ type }}</v-chip
              >
            </v-row>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom="">
            <template v-slot:activator="{ on }">
              <v-btn @click="attend(item.id, item.permitId)" v-on="on" icon>
                <v-icon color="primary"> alarm_add </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('tsjyl-hdhwr') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <Pagination />
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      fullName: "",
      headers: [
        {
          text: "",
          value: "actions",
        },
        {
          text: this.$t('rqm-altkhwyl'),
          value: "permit.sepuence",
        },
        {
          text: this.$t('alism-althlathy-llzaer'),
          value: "fullName",
        },
        {
          text: this.$t('alsfh'),
          value: "jobTitle",
        },
        {
          text: this.$t('aljhh-alzaerh'),
          value: "visiting",
        },
        {
          text: this.$t('alajhzh'),
          value: "devices",
        },
        {
          text: this.$t('nwa-alajlh'),
          value: "carName",
        },
        {
          text: this.$t('lwn-alajlh'),
          value: "carColor",
        },
        {
          text: this.$t('mwdyl-alajlh'),
          value: "caryear",
        },
        {
          text: this.$t('nwa-altkhwyl'),
          value: "type",
        },
        {
          text: this.$t('aljhh-altalbh-lltkhwyl'),
          value: "permit.organization.name",
        },
        {
          text: this.$t('mlahzh'),
          value: "note",
        },
        {
          text: this.$t('akhr-tarykh-wwqt-tsjyl-hdhwr'),
          value: "attends",
        },
      ],
    };
  },

  created() {
    this.getItems();
  },

  methods: {
    async attend(itemId, permitId) {
      this.$global.state.loading = true;
      let data = {
        userId: this.$store.state.user.id,
        permitId: permitId,
      };
      try {
        await this.$http.post(`PermitData/Attend/${itemId}`, data);
        this.$service.success(this.$t('tm-tsjyl-hdhwr-alzaer-bnjah'));
        this.getItems();
      } catch (err) {
        console.log(err);
        this.$service.faild(this.$t('hdth-khta'));
      } finally {
        this.$global.state.loading = false;
      }
    },

    async getItems() {
      this.$global.state.loading = true;
      try {
        let url = `PermitData?Sequence=3&PageIndex=${
          this.$global.state.filter.pageIndex
        }&PageSize=${this.$global.state.filter.pageSize}&FullName=${
          this.fullName
        }&IsReject=false&ExpireAt=${new Date()
          .toISOString()
          .slice(0, 10)}&IsDeleted=false&Sort=desc&OrganizationId=`;
        let GroupIds = [];
        if (this.$store.state.user.user.userGroups.length > 0) {
          await this.$store.state.user.user.userGroups.forEach((element) => {
            GroupIds.push(element.groupId);
          });
        }
        for (let j = 0; j < GroupIds.length; j++) {
          url = url + `&GroupIds=${GroupIds[j]}`;
        }
        const res = await this.$http.get(url);
        this.items = res.data.result;
        this.$global.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].type = this.items[i].type.split(",");
        }
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
  },
};
</script>
